export function parseQueryParams(url: string): { [key: string]: string | string[] } {
  const queryString = url.split('?')[1];
  if (!queryString) {
    return {};
  }

  const queryParams: { [key: string]: string | string[] } = {};
  const pairs = queryString.split('&');

  pairs.forEach(pair => {
    const [key, value] = pair.split('=');
    const decodedKey = decodeURIComponent(key);
    const decodedValue = decodeURIComponent(value || '');

    if (queryParams.hasOwnProperty(decodedKey)) {
      if (Array.isArray(queryParams[decodedKey])) {
        (queryParams[decodedKey] as string[]).push(decodedValue);
      } else {
        queryParams[decodedKey] = [
          queryParams[decodedKey] as string,
          decodedValue
        ];
      }
    } else {
      queryParams[decodedKey] = decodedValue;
    }
  });

  return queryParams;
}
