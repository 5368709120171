import { IAppConfig, APP_CONFIG } from '@tcc-mono/shared/app-config';
import { Inject } from '@angular/core';
import dayjs from 'dayjs';

export interface ILoginParams {
  state: string;
  code_challenge: string;
}

export class LoginParams implements ILoginParams {
  response_type: string;
  state: string;
  client_id: string;
  scope: string;
  code_challenge: string;
  code_challenge_method: string;
  redirect_uri: string;

  constructor(
    params: ILoginParams,
    @Inject(APP_CONFIG) _envConfig: IAppConfig
  ) {
    const { state, code_challenge } = params;
    this.state = state;
    this.code_challenge = code_challenge;
    this.client_id = _envConfig.tccCoreAuthentication.clientId;
    this.redirect_uri = _envConfig.tccCoreAuthentication.redirectUrl;
    this.code_challenge_method = 'S256';
    this.response_type = 'code';
    this.scope = '*';
  }
}

export interface ITokenParams {
  code: string;
  code_verifier: string;
}

export class TokenParams implements ITokenParams {
  grant_type: string;
  code: string;
  code_verifier: string;
  redirect_uri: string;
  client_id: string;

  constructor(
    tokenParams: ITokenParams,
    @Inject(APP_CONFIG) _envConfig: IAppConfig
  ) {
    const { code, code_verifier } = tokenParams;
    this.grant_type = 'authorization_code';
    this.code = code;
    this.code_verifier = code_verifier;
    this.redirect_uri = _envConfig.tccCoreAuthentication.redirectUrl;
    this.client_id = _envConfig.tccCoreAuthentication.clientId;
  }
}

export interface IAuthResult {
  access_token?: string;
  expires_in?: number;
  refresh_token?: string;
  token_type?: string;
}

export class AuthResult implements IAuthResult {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  token_type: string;
  valid_until: string;

  constructor(
    authResult?: IAuthResult
  ) {
    const { access_token, expires_in, refresh_token, token_type } = authResult || {};
    this.access_token = access_token || '';
    this.expires_in = expires_in || 300;
    this.refresh_token = refresh_token || '';
    this.token_type = token_type || 'Bearer';
    this.valid_until = dayjs().add(this.expires_in, 'milliseconds').format();
  }
}
