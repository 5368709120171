import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Route, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpHeaderInterceptor } from './interceptors/http-header.interceptor';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';

const routes: Route[] = [
  {
    path: 'callback',
    loadChildren: () => import('./pages/callback/callback.module').then(m => m.CallbackPageModule)
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    IonicModule
  ]
})
export class TccCoreAuthModule {
  public static forRoot(): ModuleWithProviders<TccCoreAuthModule> {
    return {
      ngModule: TccCoreAuthModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HttpHeaderInterceptor,
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HttpErrorInterceptor,
          multi: true
        },
        InAppBrowser
      ]
    }
  }
}
