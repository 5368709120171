import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

import {
  faBookSection,
  faLock,
  faMars,
  faVenus,
  faCircleCheck,
  faCircleXmark,
  faPassport,
  faMerge,
  faTruckArrowRight,
  faCheck,
  faFileExcel
} from '@fortawesome/pro-light-svg-icons';

const icons: IconDefinition[] = [
  faBookSection,
  faLock,
  faMars,
  faVenus,
  faCircleCheck,
  faCircleXmark,
  faPassport,
  faMerge,
  faTruckArrowRight,
  faCheck,
  faFileExcel
];

@NgModule({
  imports: [CommonModule]
})
export class AnimalSelectionIconsModule {
  constructor(lib: FaIconLibrary) {
    lib.addIcons(...icons);
  }
}
