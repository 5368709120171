export class User {
  public id: PropertyKey;
  public created_at: string;
  public updated_at: string;
  public name: string;
  public email: string;
  public two_factor_enabled: boolean;
  public role: string;
  public username?: string;

  public constructor(
    user?: Partial<User>
  ) {
    this.name = user?.name;
    this.email = user?.email;
    this.username = user?.username;
  }
}
