export class Response<TEntity> {
  data: TEntity;
  meta: any;
}

export class PaginatedResponse<TEntity> {
  data: TEntity[];
  links: PaginatedResponseLinks;
  meta: PaginatedResponseMeta;
}

export class PaginatedResponseLinks {
  first: string;
  last: string;
  next: string;
  prev: string;
}

export class PaginatedResponseMeta {
  current_page: number;
  from: number;
  last_page?: number;
  links?: PaginatedResponseMetaLink[];
  path: string;
  per_page: number;
  to: number;
  total?: number;
}

export class PaginatedResponseMetaLink {
  active: boolean;
  label: string;
  url?: string;
}
