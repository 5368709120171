import { SharedTranslationModule } from '@tcc-mono/shared/translation';
import { getAppConfigProvider } from '@tcc-mono/shared/app-config';
import { VanDijkWebShellModule } from '@tcc-mono/vandijk/web/shell/feature';
import { NgModule } from '@angular/core';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { HttpClientModule } from '@angular/common/http';
@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    IonicModule.forRoot(),
    VanDijkWebShellModule,
    HttpClientModule,
    SharedTranslationModule.forRoot(environment)
  ],
  providers: [
    getAppConfigProvider(environment),
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
