import { IonicModule } from '@ionic/angular';
import { Route, RouterModule } from '@angular/router';
import { InjectionToken, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnimalSelectionShellComponent } from './animal-selection-shell.component';
import { AnimalSelectionIconsModule } from '@tcc-mono/vandijk/shared/features/animal-selections/ui/animal-selection-icons';
import { AnimalSelectionTranslationsModule } from '@tcc-mono/vandijk/shared/features/animal-selections/ui/animal-selection-translations';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';

dayjs.extend(customParseFormat);

const routes: Route[] = [
  {
    path: '',
    component: AnimalSelectionShellComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import(
            '@tcc-mono/vandijk/shared/features/animal-selections/feature/animal-selection-list'
          ).then(m => m.AnimalSelectionListModule)
      },
      {
        path: ':id',
        loadChildren: () =>
          import(
            '@tcc-mono/vandijk/shared/features/animal-selections/feature/animal-selection'
          ).then(m => m.AnimalSelectionModule)
      }
    ]
  }
];

export class AnimalSelectionConfig {
  constructor(
    public source: string
  ) { }
}

export const ANIMAL_SELECTION_CONFIG = new InjectionToken<AnimalSelectionConfig>('AnimalSelectionConfig');

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    RouterModule.forChild(routes),
    AnimalSelectionIconsModule,
    AnimalSelectionTranslationsModule
  ],
  declarations: [AnimalSelectionShellComponent]
})
export class AnimalSelectionShellModule {
  static forRoot(config: AnimalSelectionConfig) {
    return {
      ngModule: AnimalSelectionShellModule,
      providers: [
        {
          provide: ANIMAL_SELECTION_CONFIG, useValue: config
        }
      ]
    }
  }
}
