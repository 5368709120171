import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { authGuard } from '@tcc-mono/shared/authentication/tcc-core-auth';

const routes: Routes = [
  {
    path: 'app',
    canActivate: [authGuard],
    loadChildren: () => import('@tcc-mono/vandijk/web/home/feature').then(m => m.HomeModule)
  },
  {
    path: 'auth',
    loadChildren: () => import('@tcc-mono/shared/authentication/tcc-core-auth').then(m => m.TccCoreAuthModule)
  },
  {
    path: '',
    redirectTo: 'app',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: 'app'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class VanDijkWebShellRoutingModule { }
