import { Inject, Injectable, NgZone } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  BehaviorSubject,
  EMPTY,
  Observable,
  filter,
  map,
  of,
  switchMap,
  take,
  tap,
  throwError
} from 'rxjs';
import { createCodeChallenge, randomString } from '../utils/string.util';
import { AuthStorageService } from './auth-storage.service';
import {
  AuthResult,
  IAuthResult,
  LoginParams,
  TokenParams
} from '../models/authentication.model';
import { Platform } from '@ionic/angular';
import {
  InAppBrowser,
  InAppBrowserOptions
} from '@awesome-cordova-plugins/in-app-browser/ngx';
import { parseQueryParams } from '../utils/params.util';
import { HttpClient } from '@angular/common/http';
import { Response, User } from '../models';
import { APP_CONFIG, IAppConfig } from '@tcc-mono/shared/app-config';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private readonly _BASE_URL: string = 'oauth';
  private readonly _AUTH_STATE_NAME: string = 'login_state';

  public authResult: AuthResult;

  private _me = new BehaviorSubject<User>(null);
  public me$ = this._me.asObservable();

  public checkAuth = (): Observable<unknown> => {
    return this._route.queryParams.pipe(
      switchMap((params: Params) => {
        if (params['code'] && params['state']) {
          return this._getAccessToken(params);
        }
        return of({});
      })
    );
  };

  public constructor(
    @Inject(APP_CONFIG) private readonly _env: IAppConfig,
    private readonly _route: ActivatedRoute,
    private readonly _router: Router,
    private readonly _storage: AuthStorageService,
    private readonly _platform: Platform,
    private readonly _iab: InAppBrowser,
    private readonly _http: HttpClient,
    private readonly _ngZone: NgZone
  ) {}

  public login = (): void => {
    const state = randomString(40);
    const codeVerifier = randomString(128);
    const returnUrl = window.location.href.replace(window.location.origin, '');
    const queryParams = JSON.stringify(this._route.snapshot.queryParams);

    this._storage.set(this._AUTH_STATE_NAME, {
      state,
      codeVerifier,
      returnUrl,
      queryParams
    });

    const params: LoginParams = new LoginParams(
      {
        state,
        code_challenge: createCodeChallenge(codeVerifier)
      },
      this._env
    );

    const authUrl: string = `${this._env.authUrl}${
      this._BASE_URL
    }/authorize?${new URLSearchParams(params as any).toString()}`;

    if (this._platform.is('capacitor')) {
      let options: InAppBrowserOptions = {};

      if (this._platform.is('ios')) {
        options = {
          zoom: 'no',
          location: 'no',
          toolbar: 'no'
        };
      } else {
        options = {
          location: 'no'
        };
      }

      const browser = this._iab.create(authUrl, '_blank', options);

      browser
        .on('loadstart')
        .pipe(
          filter(event =>
            event.url.includes(this._env.tccCoreAuthentication?.redirectUrl)
          ),
          take(1),
          tap(event => {
            browser.close();

            this._ngZone.run(() => {
              const params = parseQueryParams(event.url);
              this._router.navigate(['/auth/callback'], {
                queryParams: params
              });
            });
          })
        )
        .subscribe();
    } else {
      window.location.href = authUrl;
    }
  };

  public logout = (): void => {
    this._storage.set(this._AUTH_STATE_NAME, null);
    this.authResult = null;
    this._me.next(null);

    const logoutUrl = `${this._env.authUrl}logout?redirect_url=${this._env.tccCoreAuthentication?.redirectUrl}`;

    if (this._platform.is('capacitor')) {
      let options: InAppBrowserOptions = {};

      if (this._platform.is('ios')) {
        options = {
          zoom: 'no',
          location: 'no',
          toolbar: 'no'
        };
      } else {
        options = {
          location: 'no'
        };
      }

      setTimeout(() => {
        const logoutBrowser = this._iab.create(logoutUrl, '_blank', options);
        logoutBrowser
          .on('loadstart')
          .pipe(
            filter(
              event =>
                !event.url.includes('redirect_url') &&
                event.url.includes(this._env.tccCoreAuthentication?.redirectUrl)
            ),
            take(1),
            tap(event => logoutBrowser.close())
          )
          .subscribe();

        logoutBrowser
          .on('exit')
          .pipe(take(1))
          .subscribe(() => {
            setTimeout(() => this.login());
          });
      }, 500);
    } else {
      setTimeout(() => (window.location.href = logoutUrl), 200);
    }
  };

  public updatePasswords = (body: {
    current_password: string;
    new_password: string;
  }): Observable<unknown> => {
    return this._http.post(`${this._env.authUrl}api/me/password`, body);
  };

  public getMe = (): Observable<unknown> => {
    return this._http
      .get<Response<User>>(`${this._env.authUrl}api/me`)
      .pipe(tap(({ data }) => this._me.next(data)));
  };

  public routeToStoredRoute = (): void => {
    const url =
      this._storage.get<{ [key: string]: string }>(this._AUTH_STATE_NAME)?.[
        'returnUrl'
      ] ?? '/';
    const queryParamString: string = this._storage.get<{
      [key: string]: string;
    }>(this._AUTH_STATE_NAME)?.['queryParams'];
    const queryParams = queryParamString ? JSON.parse(queryParamString) : null;

    this._router.navigate([url.indexOf('/auth/callback') !== -1 ? '/' : url], {
      queryParams: queryParams,
      state: { forceReload: true }
    });
  };

  public isLoggedIn = (): Observable<boolean> => {
    return this.me$.pipe(map((me: User) => !!me));
  };

  public getRole = (): Observable<string> => {
    return this.me$.pipe(map((me: User) => me.role));
  };

  private _getAccessToken = ({ code, state }: Params): Observable<unknown> => {
    const storedState = this._storage.get<{ [key: string]: string }>(
      this._AUTH_STATE_NAME
    )?.['state'];
    const storedCodeVerifier = this._storage.get<{ [key: string]: string }>(
      this._AUTH_STATE_NAME
    )?.['codeVerifier'];

    if (state !== storedState) {
      return throwError(() => 'invalid state');
    }

    const params = new TokenParams(
      {
        code: code,
        code_verifier: storedCodeVerifier
      },
      this._env
    );

    return this._http
      .post<IAuthResult>(`${this._env.authUrl}${this._BASE_URL}/token`, params)
      .pipe(
        tap((response: IAuthResult) => this._handleTokenResponse(response))
      );
  };

  private _handleTokenResponse = (response: IAuthResult) => {
    this.authResult = new AuthResult(response);
  };
}
