import { PropertyValuePipe } from './pipes/property-value.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalcAgePipe } from './pipes/calc-age.pipe';
import { IsTruthyPipe } from './pipes/truthy.pipe';
import { ClickStopPropagationDirective } from './directives/click-stop-propagation/src/lib/click-stop-propagation.directive';
import { SanitizeImagePipe } from './pipes/sanitize-image.pipe';
import { TextSelectionDirective } from './directives/text-selection/src/lib/text-selection.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [
    PropertyValuePipe,
    CalcAgePipe,
    IsTruthyPipe,
    ClickStopPropagationDirective,
    SanitizeImagePipe,
    TextSelectionDirective
  ],
  exports: [
    PropertyValuePipe,
    CalcAgePipe,
    IsTruthyPipe,
    ClickStopPropagationDirective,
    SanitizeImagePipe,
    TextSelectionDirective
  ],
  providers: [
    PropertyValuePipe,
    CalcAgePipe,
    SanitizeImagePipe
  ]
})
export class SharedUtilsModule { }
