import CryptoJS from 'crypto-js';

export function randomString(length: number) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function createCodeChallenge(code: string): string {
  const codeVerifierHash = CryptoJS
    .SHA256(code)
    .toString(CryptoJS.enc.Base64);

  const codeChallenge = codeVerifierHash
    .replace(/=/g, '')
    .replace(/\+/g, '-')
    .replace(/\//g, '_');

  return codeChallenge;
}
