import { NgModule } from '@angular/core';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import {
  faStar as fadStar,
  faChevronLeft as fadChevronLeft,
  faCommand as fadCommand,
  faGaugeMin as fadGaugeMin,
  faGaugeLow as fadGaugeLow,
  faGaugeHigh as fadGaugeHigh,
  faGaugeMax as fadGaugeMax,
  faHouseWindow as fadHouseWindow,
  faPeopleGroup as fadPeopleGroup,
  faDatabase as fadDatabase,
  faUserPlus as fadUserPlus,
  faXmarkLarge as fadXmarkLarge,
  faAlbumCollectionCirclePlus as fadAlbumCollectionCirclePlus,
  faLayerPlus as fadLayerPlus,
  faLayerGroup as fadLayerGroup,
  faChevronRight as fadChevronRight,
  faChevronsLeft as fadChevronsLeft,
  faChevronsRight as fadChevronsRight,
  faEnvelope as fadEnvelope,
} from '@fortawesome/pro-duotone-svg-icons';
import {
  faStar as falStar,
  faChevronLeft as falChevronLeft,
  faCommand as falCommand,
  faGaugeMin as falGaugeMin,
  faGaugeLow as falGaugeLow,
  faGaugeHigh as falGaugeHigh,
  faGaugeMax as falGaugeMax,
  faHouseWindow as falHouseWindow,
  faPeopleGroup as falPeopleGroup,
  faDatabase as falDatabase,
  faUserPlus as falUserPlus,
  faXmarkLarge as falXmarkLarge,
  faAlbumCollectionCirclePlus as falAlbumCollectionCirclePlus,
  faLayerPlus as falLayerPlus,
  faLayerGroup as falLayerGroup,
  faChevronRight as falChevronRight,
  faChevronsLeft as falChevronsLeft,
  faChevronsRight as falChevronsRight,
  faEnvelope as falEnvelope,
} from '@fortawesome/pro-light-svg-icons';
import {
  faStar as farStar,
  faChevronLeft as farChevronLeft,
  faCommand as farCommand,
  faGaugeMin as farGaugeMin,
  faGaugeLow as farGaugeLow,
  faGaugeHigh as farGaugeHigh,
  faGaugeMax as farGaugeMax,
  faHouseWindow as farHouseWindow,
  faPeopleGroup as farPeopleGroup,
  faDatabase as farDatabase,
  faUserPlus as farUserPlus,
  faXmarkLarge as farXmarkLarge,
  faAlbumCollectionCirclePlus as farAlbumCollectionCirclePlus,
  faLayerPlus as farLayerPlus,
  faLayerGroup as farLayerGroup,
  faChevronRight as farChevronRight,
  faChevronsLeft as farChevronsLeft,
  faChevronsRight as farChevronsRight,
  faEnvelope as farEnvelope,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faStar as fatStar,
  faChevronLeft as fatChevronLeft,
  faCommand as fatCommand,
  faGaugeMin as fatGaugeMin,
  faGaugeLow as fatGaugeLow,
  faGaugeHigh as fatGaugeHigh,
  faGaugeMax as fatGaugeMax,
  faHouseWindow as fatHouseWindow,
  faPeopleGroup as fatPeopleGroup,
  faDatabase as fatDatabase,
  faUserPlus as fatUserPlus,
  faXmarkLarge as fatXmarkLarge,
  faAlbumCollectionCirclePlus as fatAlbumCollectionCirclePlus,
  faLayerPlus as fatLayerPlus,
  faLayerGroup as fatLayerGroup,
  faChevronRight as fatChevronRight,
  faChevronsLeft as fatChevronsLeft,
  faChevronsRight as fatChevronsRight,
  faEnvelope as fatEnvelope,
} from '@fortawesome/pro-thin-svg-icons';
import {
  faStar as fasStar,
  faChevronLeft as fasChevronLeft,
  faCommand as fasCommand,
  faGaugeMin as fasGaugeMin,
  faGaugeLow as fasGaugeLow,
  faGaugeHigh as fasGaugeHigh,
  faGaugeMax as fasGaugeMax,
  faHouseWindow as fasHouseWindow,
  faPeopleGroup as fasPeopleGroup,
  faDatabase as fasDatabase,
  faUserPlus as fasUserPlus,
  faXmarkLarge as fasXmarkLarge,
  faAlbumCollectionCirclePlus as fasAlbumCollectionCirclePlus,
  faLayerPlus as fasLayerPlus,
  faLayerGroup as fasLayerGroup,
  faChevronRight as fasChevronRight,
  faChevronsLeft as fasChevronsLeft,
  faChevronsRight as fasChevronsRight,
  faEnvelope as fasEnvelope,
} from '@fortawesome/pro-solid-svg-icons';
import {
  faStar as fassStar,
  faChevronLeft as fassChevronLeft,
  faCommand as fassCommand,
  faGaugeMin as fassGaugeMin,
  faGaugeLow as fassGaugeLow,
  faGaugeHigh as fassGaugeHigh,
  faGaugeMax as fassGaugeMax,
  faHouseWindow as fassHouseWindow,
  faPeopleGroup as fassPeopleGroup,
  faDatabase as fassDatabase,
  faUserPlus as fassUserPlus,
  faXmarkLarge as fassXmarkLarge,
  faAlbumCollectionCirclePlus as fassAlbumCollectionCirclePlus,
  faLayerPlus as fassLayerPlus,
  faLayerGroup as fassLayerGroup,
  faChevronRight as fassChevronRight,
  faChevronsLeft as fassChevronsLeft,
  faChevronsRight as fassChevronsRight,
  faEnvelope as fassEnvelope,
} from '@fortawesome/sharp-solid-svg-icons';
import {

} from '@fortawesome/free-brands-svg-icons';

const icons = [
  fadStar,
  falStar,
  farStar,
  fasStar,
  fatStar,
  fadChevronLeft,
  falChevronLeft,
  farChevronLeft,
  fatChevronLeft,
  fasChevronLeft,
  fadCommand,
  falCommand,
  farCommand,
  fatCommand,
  fasCommand,
  fadGaugeMin,
  fadGaugeLow,
  fadGaugeHigh,
  fadGaugeMax,
  falGaugeMin,
  falGaugeLow,
  falGaugeHigh,
  falGaugeMax,
  farGaugeMin,
  farGaugeLow,
  farGaugeHigh,
  farGaugeMax,
  fatGaugeMin,
  fatGaugeLow,
  fatGaugeHigh,
  fatGaugeMax,
  fasGaugeMin,
  fasGaugeLow,
  fasGaugeHigh,
  fasGaugeMax,
  fadHouseWindow,
  falHouseWindow,
  farHouseWindow,
  fatHouseWindow,
  fasHouseWindow,
  fadPeopleGroup,
  falPeopleGroup,
  farPeopleGroup,
  fatPeopleGroup,
  fasPeopleGroup,
  fassStar,
  fassChevronLeft,
  fassCommand,
  fassGaugeMin,
  fassGaugeLow,
  fassGaugeHigh,
  fassGaugeMax,
  fassHouseWindow,
  fassPeopleGroup,
  fadDatabase,
  falDatabase,
  farDatabase,
  fatDatabase,
  fasDatabase,
  fassDatabase,
  fadUserPlus,
  falUserPlus,
  farUserPlus,
  fatUserPlus,
  fasUserPlus,
  fassUserPlus,
  fadXmarkLarge,
  falXmarkLarge,
  farXmarkLarge,
  fatXmarkLarge,
  fasXmarkLarge,
  fassXmarkLarge,
  fadAlbumCollectionCirclePlus,
  falAlbumCollectionCirclePlus,
  farAlbumCollectionCirclePlus,
  fatAlbumCollectionCirclePlus,
  fasAlbumCollectionCirclePlus,
  fassAlbumCollectionCirclePlus,
  fadLayerPlus,
  falLayerPlus,
  farLayerPlus,
  fatLayerPlus,
  fasLayerPlus,
  fassLayerPlus,
  fadLayerGroup,
  falLayerGroup,
  farLayerGroup,
  fatLayerGroup,
  fasLayerGroup,
  fassLayerGroup,
  fadChevronRight,
  fadChevronsLeft,
  fadChevronsRight,
  falChevronRight,
  falChevronsLeft,
  falChevronsRight,
  farChevronRight,
  farChevronsLeft,
  farChevronsRight,
  fatChevronRight,
  fatChevronsLeft,
  fatChevronsRight,
  fasChevronRight,
  fasChevronsLeft,
  fasChevronsRight,
  fassChevronRight,
  fassChevronsLeft,
  fassChevronsRight,
  fadEnvelope,
  falEnvelope,
  farEnvelope,
  fatEnvelope,
  fasEnvelope,
  fassEnvelope,
]

@NgModule({
  imports: [FontAwesomeModule],
})
export class FontawesomeImportModule {
  constructor(
    lib: FaIconLibrary
  ) {
    lib.addIcons(...icons);
  }
}
