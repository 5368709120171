import { NgModule } from '@angular/core';
import { TranslocoModule, TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { scopeLoader } from '@tcc-mono/shared/utils';

@NgModule({
  imports: [TranslocoModule],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: 'animalSelections',
        loader: scopeLoader(
          (lang: string, root: string) => import(`../${root}/${lang}.json`)
        )
      }
    }
  ]
})
export class AnimalSelectionTranslationsModule {}
