import { ToastService } from '@tcc-mono/shared/ui/services';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private _toast: ToastService
  ) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this._toast.show({
            header: error.status.toString(),
            message: error.error?.error || error.error?.message || error.message || error,
            color: 'danger'
          });

          return throwError(() => error);
        })
      );
  }
}
