import { inject } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { Observable, tap } from 'rxjs';

export const authGuard = (): Observable<boolean> => {
  const authService = inject(AuthenticationService);

  return authService.isLoggedIn()
    .pipe(
      tap((isLoggedIn: boolean) => {
        if (!isLoggedIn) {
          authService.login();
        }
      })
    );
}
